import React from "react";
import styled from "styled-components";
import CollapsibleSide from "./CollapsibleSide";
import { Link } from "react-router-dom";
import { Menu, Icon } from "antd";

import LogoLarge from "../logo/logoLarge";
import LogoMini from "../logo/logoMini";

import AccountIcon from "../../../static/assets/icon/profile-icon.svg";
import ACPIcon from "../../../static/assets/icon/acp-icon.svg";
import BucketsIcon from "../../../static/assets/icon/buckets-icon.svg";
import LearnIcon from "../../../static/assets/icon/learn-icon-sidebar.png";
import FuneralIcon from "../../../static/assets/icon/funeral-icon.svg";
import LastWillIcon from "../../../static/assets/icon/last-will-icon.svg";
import OnlineAccountsIcon from "../../../static/assets/icon/online-accounts-icon.svg";
import GoodByeMessageIcon from "../../../static/assets/icon/goodbye-message-icon.svg";
import PrivateNotesIcon from "../../../static/assets/icon/privete-notes-icon.svg";
import DashboardIcon from "../../../static/assets/icon/dashboard-icon.svg";
import CommentIcon from "../../../static/assets/icon/comment-icon.svg";
import NewsIcon from "../../../static/assets/icon/news-icon.svg";
import { JWT_LOCAL_STORAGE } from "../../constants/HostConstants";
import { decodeUserId } from "../../utils/ActionUtils";
import { GB_SOUTHSTAFFORDSHIRE_DEMO_ZIPCODE } from "../../constants/PostalWhitelistConstans";
import { GGTranslate } from "../../../common/components/GGTranslate/GGTranslate";
import { connect } from "react-redux";

const LogoWrapper = styled.div`
	height: 50px;
	margin: 16px;
`;

const CustomSpan = styled.span`
	color: #fff;
`;

const MenuItemWrapper = styled.div`
	margin-top: 16px;
`;

class SidebarNav extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			content_access: null,
		};
	}

	componentWillMount() {
		const token = localStorage.getItem(JWT_LOCAL_STORAGE);
		// check if token exists, if it isn't then force it to login first
		// exclude the public page
		if (token) {
			const user = decodeUserId(token);
			console.log(user);
			this.setState({ content_access: user.content_access });
		}
	}

	render() {
		const { isNavCollapsed, userPostalCode, user } = this.props;
		const { content_access } = this.state;

		const USAccess = content_access && content_access.ad;

		return (
			<CollapsibleSide
				style={{
					backgroundColor: "#44617D",
					overflow: "auto",
					height: "100vh",
					position: "fixed",
					left: 0,
				}}
				width={250}
				collapsed={isNavCollapsed}
			>
				{" "}
				<LogoWrapper>
					<Link style={{ color: "#fff" }} to="/">
						{isNavCollapsed ? <LogoMini /> : <LogoLarge />}
					</Link>
				</LogoWrapper>
				<Menu
					selectable={false}
					style={{
						backgroundColor: "#44617D",
						border: "none",
						paddingTop: "24px",
					}}
					mode="inline"
				>
					<Menu.Item key="0">
						<Link to="/" className="mywishes-menu-link">
							<img
								className="mywishes-icon anticon"
								src={DashboardIcon}
								alt="icon"
							/>
							<CustomSpan>Dashboard</CustomSpan>
						</Link>
					</Menu.Item>
					<Menu.Item key="1" style={{ backgroundColor: "#8181BC" }}>
						<Link to="/my-profile" className="mywishes-menu-link">
							<img
								className="mywishes-icon anticon"
								src={AccountIcon}
								alt="icon"
							/>
							<CustomSpan>My Profile</CustomSpan>
						</Link>
					</Menu.Item>
					{user.region?.code === "hillingdon" &&
						user.sub_region?.id !== 4 && (
							<Menu.Item
								key="1"
								style={{ backgroundColor: "#62CB85" }}
							>
								<Link
									to="/my-wellbeing"
									className="mywishes-menu-link"
								>
									<img
										className="mywishes-icon anticon"
										src={AccountIcon}
										alt="icon"
									/>
									<CustomSpan>My Wellbeing</CustomSpan>
								</Link>
							</Menu.Item>
						)}
					<Menu.Item key="2" style={{ backgroundColor: "#5882C2" }}>
						<Link
							to="/my-will-testament"
							className="mywishes-menu-link"
						>
							<img
								className="mywishes-icon anticon"
								src={LastWillIcon}
								alt="icon"
							/>
							<CustomSpan>
								My Last Will &amp; Testament
							</CustomSpan>
						</Link>
					</Menu.Item>
					<Menu.Item key="3" style={{ backgroundColor: "#45ABC5" }}>
						<Link
							to="/my-funeral-wishes"
							className="mywishes-menu-link"
						>
							<img
								className="mywishes-icon anticon"
								src={FuneralIcon}
								alt="icon"
							/>
							<CustomSpan>My Funeral Wishes</CustomSpan>
						</Link>
					</Menu.Item>
					<Menu.Item key="4" style={{ backgroundColor: "#40B6A8" }}>
						<Link
							to={
								USAccess
									? "/my-advance-directives"
									: "/my-advance-care-plan"
							}
							className="mywishes-menu-link"
						>
							<img
								className="mywishes-icon anticon"
								src={ACPIcon}
								alt="icon"
							/>
							<CustomSpan>
								{USAccess
									? "My Advance Directives"
									: "My Advance Care Plan"}
							</CustomSpan>
						</Link>
					</Menu.Item>
					<Menu.Item key="5" style={{ backgroundColor: "#B788BC" }}>
						<Link
							to="/my-goodbye-messages"
							className="mywishes-menu-link"
						>
							<img
								className="mywishes-icon anticon"
								src={GoodByeMessageIcon}
								alt="icon"
							/>
							<CustomSpan>My Goodbye Messages</CustomSpan>
						</Link>
					</Menu.Item>
					<Menu.Item key="6" style={{ backgroundColor: "#E36184" }}>
						<Link
							to="/my-online-accounts"
							className="mywishes-menu-link"
						>
							<img
								className="mywishes-icon anticon"
								src={OnlineAccountsIcon}
								alt="icon"
							/>
							<CustomSpan>My Online Accounts</CustomSpan>
						</Link>
					</Menu.Item>
					<Menu.Item key="7" style={{ backgroundColor: "#ED6953" }}>
						<Link
							to="/my-bucket-list"
							className="mywishes-menu-link"
						>
							<img
								className="mywishes-icon anticon"
								src={BucketsIcon}
								alt="icon"
							/>
							<CustomSpan>My Bucket List</CustomSpan>
						</Link>
					</Menu.Item>
					<Menu.Item key="8" style={{ backgroundColor: "#EAA93E" }}>
						<Link
							to="/my-private-notes"
							className="mywishes-menu-link"
						>
							<img
								className="mywishes-icon anticon"
								src={PrivateNotesIcon}
								alt="icon"
							/>
							<CustomSpan>My Private Notes</CustomSpan>
						</Link>
					</Menu.Item>

					<Menu.Item key="11">
						<Link to="/tutorials">
							<img
								className="mywishes-icon anticon"
								src={LearnIcon}
								alt="icon"
							/>
							{/* <Icon
								type="read"
								theme="outlined"
								style={{ color: "#fff" }}
							/> */}
							<CustomSpan>View Tutorials</CustomSpan>
						</Link>
					</Menu.Item>

					<Menu.Item key="11">
						<Link to="/latest-news">
							<Icon
								type="notification"
								theme="outlined"
								style={{ color: "#fff" }}
							/>
							{/* <img
								className="mywishes-icon anticon"
								src={NewsIcon}
								alt="icon"
							/> */}
							<CustomSpan>Latest News</CustomSpan>
						</Link>
					</Menu.Item>

					<Menu.Item key="9">
						<Link to="/feedback" className="mywishes-menu-link">
							<img
								className="mywishes-icon anticon"
								src={CommentIcon}
								alt="icon"
							/>
							<CustomSpan>Get Help</CustomSpan>
						</Link>
					</Menu.Item>
					<Menu.Item key="10">
						<Link to="/confirm-deceased">
							<Icon
								type="audit"
								theme="outlined"
								style={{ color: "#fff" }}
							/>
							<CustomSpan>Register a Death</CustomSpan>
						</Link>
					</Menu.Item>
				</Menu>
				<GGTranslate />
			</CollapsibleSide>
		);
	}
}

export default connect(
	({ userReducers }) => ({ user: userReducers }),
	{}
)(SidebarNav);
