import axios from "axios";
import _ from "lodash";

import {
	JWT_LOCAL_STORAGE,
	URL,
} from "../../../shared/constants/HostConstants";
import userConstants from "../constants/UserConstants";
import LoadingConstants from "../../../shared/constants/LoadingConstants";
import AlertConstants from "../../../shared/constants/AlertConstants";
import {
	decodeUserId,
	requestErrorHandler,
} from "../../../shared/utils/ActionUtils";
import { setUserID } from "../../../shared/utils/GoogleAnalytics";

export function getUserInformation(history) {
	return async function (dispatch) {
		const token = localStorage.getItem(JWT_LOCAL_STORAGE);
		if (!token) {
			history.push("/login");
			dispatch({
				type: AlertConstants.SHOW_ALERT,
				message: "You need to login first",
			});
			return;
		}
		try {
			dispatch({
				type: LoadingConstants.SHOW_LOADING,
			});
			// get user ID
			const userId = decodeUserId(token);
			const res = await axios.post(
				`${URL}/user/profile`,
				{
					id: userId.id,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			dispatch({
				type: LoadingConstants.CLOSE_LOADING,
			});

			if (res.data.data != null) {
				const user = res.data.data;
				setUserID(user.id, {
					user_postal_code:
						user.user_profile?.user_addresses?.[0]?.postal_code,
					user_country: user.user_profile?.country?.id,
				});

				dispatch({
					type: userConstants.GET_USER_INFO,
					payload: user,
				});
			}
		} catch (err) {
			requestErrorHandler(err, dispatch, history);
		}
	};
}

export function editProfile(values, history) {
	return async function (dispatch) {
		// TODO: below is the guard for mutating the information if the user is already dead
		// that might be implemented later
		// const { isdead } = getState().userReducers;
		// if(isdead) {
		// 	dispatch({
		// 		type: AlertConstants.SHOW_ALERT,
		// 		message: "The user is already dead"
		// 	});
		// 	return;
		// }
		// get the token first
		const token = localStorage.getItem(JWT_LOCAL_STORAGE);
		if (!token && history) {
			history.push("/login");
			dispatch({
				type: AlertConstants.SHOW_ALERT,
				message: "You need to login first",
			});
			return;
		}
		try {
			dispatch({
				type: LoadingConstants.SHOW_LOADING,
			});
			// get user ID
			// console.log(values);
			const userId = decodeUserId(token);
			const res = await axios.put(
				`${URL}/user/profile/edit`,
				{
					id: userId.id,
					...values,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			dispatch({
				type: LoadingConstants.CLOSE_LOADING,
			});

			const {
				username,
				full_name,
				bod,
				bio,
				avatar,
				visibility,
				address,
				city,
				country,
				country_id,
				country_iso,
				country_nicename,
				sub_country,
				province,
				postal_code,
			} = values;

			const { status, user_message } = res.data;

			if (status === "success") {
				dispatch({
					type: userConstants.EDIT_USER_INFO,
					payload: {
						username,
						avatar,
						user_profile: {
							full_name,
							visibility,
							bod,
							bio,
							country: {
								id: country_id,
								iso: country_iso,
								nicename: country_nicename,
							},
							sub_country: {
								...sub_country,
							},
							user_addresses: [
								{
									address,
									city,
									country: country_id,
									province,
									postal_code,
								},
							],
						},
					},
				});
			}

			if (user_message) {
				// dispatch({
				// 	type: AlertConstants.SHOW_ALERT,
				// 	message: user_message
				// });
				return {
					status,
					user_message,
				};
			}
		} catch (err) {
			requestErrorHandler(err, dispatch, history);
		}
	};
}

export function deleteAccount(history) {
	return async function (dispatch) {
		// console.log("action delete");
		const token = localStorage.getItem(JWT_LOCAL_STORAGE);
		if (!token) {
			history.push("/login");
			dispatch({
				type: AlertConstants.SHOW_ALERT,
				message: "You need to login first",
			});
			return;
		}
		try {
			dispatch({
				type: LoadingConstants.SHOW_LOADING,
			});
			const deocodedJWT = decodeUserId(token);
			// console.log(deocodedJWT);
			const res = await axios.delete(
				`${URL}/user/${deocodedJWT.id}/delete`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			dispatch({
				type: LoadingConstants.CLOSE_LOADING,
			});

			const { status, user_message } = res.data;
			if (status === "success") {
				dispatch({
					type: userConstants.DELETE_USER_ACCOUNT,
					payload: res.data,
				});

				if (user_message) {
					history.push("/login");
					dispatch({
						type: AlertConstants.SHOW_ALERT,
						message: user_message,
					});
					return;
				} else {
					history.push("/login");
					dispatch({
						type: AlertConstants.SHOW_ALERT,
						message: "You MyWishes account have been deleted",
					});
					return;
				}
			}
		} catch (error) {
			requestErrorHandler(error, dispatch, history);
		}
	};
}

export function updateBanner(bannerURL) {
	return {
		type: userConstants.EDIT_BANNER,
		payload: bannerURL,
	};
}

export function getUserSocialProfile(history) {
	return async function (dispatch) {
		try {
			// console.log("ACTION GET USER SOCIAL PROFILE");
			const token = localStorage.getItem(JWT_LOCAL_STORAGE);
			if (!token) {
				history.push("/login");
				dispatch({
					type: AlertConstants.SHOW_ALERT,
					message: "You need to login first",
				});
				return;
			}

			dispatch({
				type: LoadingConstants.SHOW_LOADING,
			});
			// get user ID
			const decodedToken = decodeUserId(token);
			// console.log("USER IDDDD");
			// console.log(decodedToken);
			const res = await axios.get(
				`${URL}/users/${_.get(decodedToken, "id")}/socials`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			dispatch({
				type: LoadingConstants.CLOSE_LOADING,
			});

			const response = _.get(res, "data");
			// console.log("RESPONSE DATA");
			// console.log(response);
			if (!_.isEmpty(response)) {
				dispatch({
					type: userConstants.GET_USER_SOCIAL_PROFILE,
					payload: _.get(response, "data", []),
				});
			}
		} catch (err) {
			requestErrorHandler(err, dispatch, history);
		}
	};
}

export function editSocialProfile(values, history) {
	return async function (dispatch) {
		try {
			// get the token first
			const token = localStorage.getItem(JWT_LOCAL_STORAGE);
			if (!token) {
				history.push("/login");
				dispatch({
					type: AlertConstants.SHOW_ALERT,
					message: "You need to login first",
				});
				return;
			}
			dispatch({
				type: LoadingConstants.SHOW_LOADING,
			});
			// get user ID
			const decodedToken = decodeUserId(token);
			// console.log("USER IDDDD");
			// console.log(decodedToken);
			// console.log("EDIT SOCIAL PROFILE ACTION");
			// console.log(values);
			const res = await axios.post(
				`${URL}/users/${_.get(decodedToken, "id")}/socials`,
				{
					socials: values,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			// console.log("RESPONSE API");
			// console.log(res.data);

			dispatch({
				type: LoadingConstants.CLOSE_LOADING,
			});

			const { status, user_message, data } = res.data;
			// console.log("RESPONSE DATA");
			// console.log(data);

			if (status == "success") {
				dispatch({
					type: userConstants.EDIT_USER_SOCIAL_PROFILE,
					payload: data,
				});
			}

			if (user_message) {
				return {
					status,
					user_message,
				};
			}
		} catch (error) {
			requestErrorHandler(error, dispatch, history);
		}
	};
}

export function editPrivacyProfile(values, history) {
	return async function (dispatch) {
		try {
			// get the token first
			const token = localStorage.getItem(JWT_LOCAL_STORAGE);
			if (!token && history) {
				history.push("/login");
				dispatch({
					type: AlertConstants.SHOW_ALERT,
					message: "You need to login first",
				});
				return;
			}
			dispatch({
				type: LoadingConstants.SHOW_LOADING,
			});
			// get user ID
			const decodedToken = decodeUserId(token);
			// console.log("USER IDDDD");
			// console.log(decodedToken);
			// console.log("EDIT SOCIAL PROFILE ACTION");
			// console.log(values);
			const res = await axios.put(
				`${URL}/user/profile/${_.get(decodedToken, "id")}/privacy`,
				{
					visibility: _.get(values, "visibility"),
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			// console.log("RESPONSE API");
			// console.log(res.data);

			dispatch({
				type: LoadingConstants.CLOSE_LOADING,
			});

			const { status, user_message, data } = res.data;
			// console.log("RESPONSE DATA");
			// console.log(data);

			if (status == "success") {
				dispatch({
					type: userConstants.EDIT_USER_PRIVACY_PROFILE,
					payload: {
						visibility: _.get(values, "visibility"),
					},
				});
			}

			if (user_message) {
				return {
					status,
					user_message,
				};
			}
		} catch (error) {
			requestErrorHandler(error, dispatch, history);
		}
	};
}

export function downloadArchiveAction(history) {
	return async (dispatch) => {
		try {
			console.log("download archive rest call");
			const token = localStorage.getItem(JWT_LOCAL_STORAGE);
			if (!token) {
				history.push("/login");
				dispatch({
					type: AlertConstants.SHOW_ALERT,
					message: "You need to login first",
				});
				return;
			}

			// get user ID
			const decodedToken = decodeUserId(token);

			const res = await axios.post(`${URL}/api/users/archives`, null, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			const { status, message, data } = _.get(res, "data");
			// console.log("RESPONSE DATA");
			// console.log(data);

			if (status == "success") {
				console.log(message);
			}

			return;
		} catch (error) {
			requestErrorHandler(error, dispatch, history);
		}
	};
}
