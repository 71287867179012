const postcodes = require("../../common/utils/newham-postcodes.json");

export const GB_SOUTHSTAFFORDSHIRE_DEMO_ZIPCODE = postcodes;

export const GB_SOUTHSTAFFORDSHIRE_ALLOWED_ZIPCODE = [
    "B74",
    "B75",
    "B77",
    "B78",
    "B79",
    "DE1",
    "DE13",
    "DE14",
    "DE15",
    "DE6",
    "ST10",
    "ST11",
    "ST12",
    "ST14",
    "ST15",
    "ST16",
    "ST17",
    "ST18",
    "ST19",
    "ST20",
    "ST21",
    "ST3",
    "ST4",
    "ST5",
    "WS11",
    "WS12",
    "WS13",
    "WS14",
    "WS15",
    "WS3",
    "WS6",
    "WS7",
    "WS8",
    "WS9",
    "WV10",
    "WV10",
    "WV11",
    "WV15",
    "WV3",
    "WV4",
    "WV5",
    "WV6",
    "WV7",
    "WV8",
    "WV9"
];
