const S3Region = "https://s3.eu-west-2.amazonaws.com/mywishes-prod-app";

const StaticAssetsConstants = {
	mywishesLayoutBG: `${S3Region}/assets/mywishes-pattern.png`,
	defaultBanner: `${S3Region}/assets/mywishes-default-banner.jpg`,
	defaultBannerNorthernIreland: `${S3Region}/assets/mywishes-nothern-ireland-banner.jpeg`,
	defaultBannerNewham: `${S3Region}/assets/mywishes-newham-banner.jpg`,
	howToUseBG: `${S3Region}/assets/mywishes-how-to-use-bg-new.png`,
	playButton: `${S3Region}/assets/mywishes-playbutton.png`,
	legalBG: `${S3Region}/assets/mywishes-legal-bg.jpg`,
	legalBoy: `${S3Region}/assets/mywishes-legal-boy.jpg`,
	funeralBG: `${S3Region}/assets/mywishes-funeral-bg.jpg`,
	funeralHelpChart: `${S3Region}/assets/mywishes-funeral-help-chart.png`,
	helpOther: `${S3Region}/assets/mywishes-help-other.jpg`,
	mindfulnessBG: `${S3Region}/assets/mywishes-mindfulness-bg.jpg`,
	donateBG: `${S3Region}/assets/mywishes-donate-bg.jpg`,
	tellsomeoneBG: `${S3Region}/assets/mywishes-tellsomeone-bg.jpg`,
	conversationBG: `${S3Region}/assets/mywishes-conversations-bg.png`,
	introWillLegalVideo: `${S3Region}/assets/mywishes-will-intro.mp4`,
	introFuneralHelpVideo: `${S3Region}/assets/mywishes-myfuneral-tutorial.mp4`,
	laptopContainerBG: `${S3Region}/assets/mywishes-laptop-transparent.png`,
	invitationBG: `${S3Region}/assets/mywishes-invitation-bg.jpg`,
	mindfulnessContent: `${S3Region}/assets/mywishes-mindfullness-content.jpg`,
	onlineAccountsBG: `${S3Region}/assets/mywishes-online-accounts-bg.jpeg`,
	onlineAccountsHeader: `${S3Region}/assets/mywishes-online-account-header.png`,
	onlineAccountsScreenshot: `${S3Region}/assets/mywishes-online-account-screenshot.png`,
	privateNoteBG: `${S3Region}/assets/mywishes-private-note-bg.png`,
	USAMap: `${S3Region}/assets/mywishes-usa-map.jpg`,
	mywishesLogo: `https://s3.eu-west-2.amazonaws.com/mywishes-assets/prod/main/web/mywishes-logo-black.png`,

	ACPNorthernIreland: {
		havingConversations: `${S3Region}/advance/northern-ireland/having_conversations.jpg`,
		myLikeDislikes: `${S3Region}/advance/northern-ireland/my_likes_dislikes.jpeg`,
		otherWishes: `${S3Region}/advance/northern-ireland/other_wishes.jpeg`,
		thinking: `${S3Region}/advance/northern-ireland/thinking.jpeg`,
		refusingTreatment: `${S3Region}/advance/northern-ireland/refusing_treatment.png`,
		whatMattersMost: `${S3Region}/advance/northern-ireland/what_matters_most.png`,
		northernHealthAndSCTLogo: `${S3Region}/advance/northern-ireland/NorthenHealthAndSCT.png`,
		departmentOfJustice: `${S3Region}/advance/northern-ireland/department_of_justice.png`,
	},

	Wellbeing: {
		introVideo: `${S3Region}/wellbeing/Wellbeing_Hub_Intro_Video.mp4`,
		introVideoForPopupEmpoweringContent: `${S3Region}/wellbeing/WellbeingHubIntroductionVideo.mp4`,
		hospiceLogo: `${S3Region}/wellbeing/thumbnail_logo_hospiceuk_rgb.jpeg`,
		masonicLogo: `${S3Region}/wellbeing/Masonic_Charitable_Foundation.jpeg`,
		hallingtonLogo: `${S3Region}/wellbeing/hardligton_hospice_logo.png`,
		videoPoster: `${S3Region}/wellbeing/Wellbeing-video-poster.png`,
		hallingtonCareLogo: `${S3Region}/wellbeing/Harlington-Hospice-logo-care.png`,
		spiritualVideo: `${S3Region}/wellbeing/Riz-Spirituality-Wellbeing+Hub-V2.mp4`,
		bereavementJourneyVideo: `${S3Region}/wellbeing/Whirlpool-fo-grief-2023.mp4`,
		introductionRelaxation: `${S3Region}/wellbeing/Introduction_to_Relaxation.mp4`,
		audioProgressiveMuscularRelaxation: `${S3Region}/wellbeing/Progressive_Muscular_Relaxation.MP3`,
		audioVisualisationRelaxationTheBeach: `${S3Region}/wellbeing/Visualisation_Relaxation_The_Beach.MP3`,
		audioVisualisationRelaxationACountryCottageGarden: `${S3Region}/wellbeing/Visualusation_Relaxation_A_Country_Cottage_Garden.MP3`,
	},

	video: {
		letterOfWishes: `https://mywishes-assets.s3.eu-west-2.amazonaws.com/prod/app/Letter+of+Wishes+Youtube.mp4`,
		introductionVideo: `${S3Region}/assets/Getting-Started-with-MyWishes-2021+WEB.mp4`,
		tutorialDigitalWill: `${S3Region}/assets/mywishes-tutorial-digital-will.mp4`,
		tutorialBucketList: `${S3Region}/assets/mywishes-tutorial-bucketlist.mp4`,
		tutorialFuneral: `https://mywishes-prod-app.s3.eu-west-2.amazonaws.com/assets/mywishes-funeral-wishes-tutorial.mp4`,
		tutorialACP: `${S3Region}/assets/mywishes-tutorial-acp.mp4`,
		tutorialDNACPR: `https://mywishes-prod-app.s3.eu-west-2.amazonaws.com/assets/mywishes-talk-CPR-Doctors-Speak-about-Sharing-and-Involving-and-challenges-in-our-approach-to-DNACPR.mp4`,
		tutorialPosturalCare: `https://mywishes-prod-app.s3.eu-west-2.amazonaws.com/assets/mywishes-Postual-Care-Passport-video.mp4`,
		funeralPlaylist: `https://mywishes-assets.s3.eu-west-2.amazonaws.com/prod/main/web/home/Funeral+Playlist-v4-music.mp4`,

		tripudioVideo: `https://mywishes-assets.s3.eu-west-2.amazonaws.com/prod/app/Tripudio_Final_Cut.mp4`,
	},
};

export default StaticAssetsConstants;
